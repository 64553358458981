<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card width="400" class="mx-auto mt-5">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Register</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-form @submit.prevent="register">
              <v-text-field
                label="First name"
                v-model="firstName"
                @blur="$v.firstName.$touch()"
              />

              <div class="red--text text--lighten-1" v-if="$v.firstName.$error">
                <div v-if="!$v.firstName.required">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  First name is required
                </div>
              </div>

              <v-text-field
                label="Last name"
                v-model="lastName"
                @blur="$v.lastName.$touch()"
              />

              <div class="red--text text--lighten-1" v-if="$v.lastName.$error">
                <div v-if="!$v.lastName.required">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Last name is required
                </div>
              </div>

              <v-text-field
                label="Email"
                prepend-icon="mdi-email"
                v-model="email"
              />

              <v-text-field
                :type="showPassword1 ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword1 = !showPassword1"
                v-model="password1"
                @blur="$v.password1.$touch()"
              />

              <div class="red--text text--lighten-1" v-if="$v.password1.$error">
                <div v-if="!$v.password1.required">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Password required
                </div>

                <div v-if="!$v.password1.minLength">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Password must be at least 8 characters
                </div>

                <div v-if="!$v.password1.hasUpperCase">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have an uppercase character
                </div>

                <div v-if="!$v.password1.hasLowerCase">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have a lowercase character
                </div>

                <div v-if="!$v.password1.hasNumber">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have at least 1 number
                </div>

                <div v-if="!$v.password1.hasSpecialCharacter">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Must have at least 1 special character
                </div>
              </div>

              <v-text-field
                :type="showPassword2 ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword2 = !showPassword2"
                v-model="password2"
                @blur="$v.password2.$touch()"
              />

              <div class="red--text text--lighten-1" v-if="$v.password2.$error">
                <div v-if="!$v.password1.sameAsPassword">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  Passwords must match
                </div>
              </div>

              <v-btn
                type="submit"
                color="success"
                name="button"
                :disabled="$v.$invalid"
              >
                Register
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn color="success">Register</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="info">Login</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import {
  hasUpperCase,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
} from "@/lib/validators/password_validators";
import api from "@/services/api.js";

export default {
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password1: "",
    password2: "",
    showPassword1: false,
    showPassword2: false,
    errors: null,
  }),

  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },

    lastName: {
      required,
      minLength: minLength(2),
    },

    password1: {
      required,
      minLength: minLength(8),
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialCharacter,
    },
    password2: {
      required,
      sameAsPassword: sameAs("password1"),
    },
  },

  methods: {
    resetForm() {
      this.$refs.signUpForm.reset();
    },

    resetValidation() {
      this.$refs.signUpForm.resetValidation();
    },

    validateForm() {
      this.$refs.signUpForm.validate();
    },

    register() {
      //console.log("Entering register method");
      return api
        .register({
          username: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          password: this.password1,
        })
        .then(() => {
          this.$router.push({ name: "registration-pending" });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
        });
    },
  },
};
</script>
